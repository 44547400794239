@import "includes";

.blockFormQuestion {
  @include clearfix();
  position: relative;
  
  .help-block,
  .help-block-rich {
    margin-bottom: 6px;
  }

  .help-block-rich:last-child {
    margin-top: 2px;
  }

  .help-block-rich p:last-child {
    margin: 0;
  }

  .form-label {
    margin-top: 0;
  }

  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  [class^="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.radioButtons,
.checkboxes {
  label {
    margin-right: 15px;
    margin-top: 4px;
    padding-left: 25px;
    word-wrap: break-word;
    user-select: none;
    
    .checkbox-material,
    .radio-material {
      margin-left: -25px;
    }
  }
}

.columnLayout {
  label {
    display: block;
    margin-left: 0 !important;
  }
}

.formQuestionHideLabel,
.formQuestionHasRichText {
  margin-top: 0 !important;
  margin-bottom: -4px;
}

.formQuestionHelpText label {
  margin-top: 0;
}

.formQuestionHelpText .redactor-box {
  margin-bottom: 20px;
}

.formQuestionHelpText .redactor-toolbar {
  position: relative !important;
}


.formQuestionAddress .formInput + .formInput {
  margin-top: 10px;
}


.formQuestionAddress__city,
.formQuestionAddress__state,
.formQuestionAddress__zip,
.viewport--xx > .row > .col-xx-12 + .col-xx-12,
.viewport--xs > .row > .col-xs-12 + .col-xs-12 {
  margin-top: 10px;
}

:root::-webkit-media-controls-panel,
.formInput > .validation__wrapper > select {
  -webkit-appearance: textfield;
  height: auto;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAeCAQAAABZVYz/AAAAmklEQVR4AWNABUJaDHgAu80rg3icsuZTjP/b/mFgh/AwDDb+D4KWS7FK29wCSoKhkg2GpFEBUAIKbV6hy0ra/gFKwKFpE4qs5TaIMAIySMIltX1hgghofRzJtwhhBDSIh/sWE0JCAOxbTAgPgSELCHiMQLCAA9X2DfZAxRclJwhEKN7kgCcx2b4hkBTxJmQysgE0BFAzEYEsCAAKK8rt24yxaAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: right 4px center;
  background-size: auto 15px;
}



.formInput {
  position: relative;
}


.suggestions__wrapper {
  position: relative;

  &.isOpen + .validation {
    display: none;
  }
}

.suggestions {
  @extend %depth-1;
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  background-color: white;
  list-style-type: none;
  z-index: z(dropdown);
  padding: 0;
}

.suggestion {
  width: 100%;
  line-height: 1.5em;
  padding: 5px 10px;
  cursor: pointer;
  
  &.selected {
    background-color: rgba($theme-primary-bright, 0.2);
  }
}