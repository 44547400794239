@import "includes";

.container,
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid::before,
.container-fluid::after,
.row::before,
.row::after {
  content: ' ';
  display: table;
  width: 0;
}

.container-fluid::after,
.row::after {
  clear: both;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

html.is-safari .column {
  margin-left: -1px;
  
  ~ .column {
    margin-left: 0px;
  }
}