.wrapper {
  margin: 0 auto;
}

.wrapper[data-fit="full"] {
  max-width: none !important;
}

.inner {
  display: block;
  position: relative;
  height: 0;
  overflow: hidden;
}

.inner > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}