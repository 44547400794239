.container {
  text-align: center;
  padding: 8em 0;
  margin: 0 auto;
  max-width: 30em;
  color: $theme-light-secondary;
  font-size: 16px;
  line-height: 1.5;
  
  i {
    vertical-align: text-bottom;
    font-size: 20px;
    margin: 0 2px;
    padding: 0 1px;
  }

  a {
    color: $theme-accent;
    text-decoration: none;
  }
}