.choices {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.choice {
  position: relative;
  margin-bottom: 6px;
  
  button {
    position: absolute;
    right: 0;
    top: 2px;
    font-size: 16px;
  }
}

.formInput .label__text {
  vertical-align: middle;
}