.header,
.footer {
  position: relative;
}

.header::before,
.header::after {
  content: " ";
  display: table;
  width: 0;
}

.header::after {
  clear: both;
}

.header :global .container-fluid {
  height: 100%;
}
