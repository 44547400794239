.blockFormBtn {
  height: auto;
}

.blockFormBtn + .blockFormBtn {
  margin-left: 12px;
}

.blockFormBtn.btn-raised + .blockFormBtn.btn-text {
  margin-left: 24px;
}

.blockFormBtn.btn-raised {
  box-shadow: none;
}