@import "./common/components/block/form/buttons";
@import "./common/components/block/form/choices";
@import "./common/components/block/form/question";
@import "./common/components/block/form/validations";

.pt {
  cursor: pointer;
}

template {
  display: none;
}

// Some customers are using this in surveys are using these classes, apparently.
.hidden,
.visible-lg,
.visible-md,
.visible-sm,
.visible-xs {
    display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .visible-sm {
    display: block !important;
  }

  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width:1199px) {
  .visible-md {
    display: block !important;
  }

  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  .hidden-lg {
    display: none !important;
  }
}
