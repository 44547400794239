@import "includes";

.validation__wrapper {
  position: relative; 

  &::after {
    content: '';
    background-repeat: no-repeat;
    background-size: 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAABgUlEQVR4AWKgFpAsuwSozg40lImiAI67U5gE6EsAqvgA7FIEWAj2KcooqR5mAfQSI71LjH2FjAGQcfa/tFzU1nRnbmfxAxh/mlvnngzqZ8b9ie4xPcwQI4XYcESMKbowPsJCrJBBCsqwQlhFWIAFpAQ5IgRlxbWRQEp2QMs1bogcUpETXh+Nm0A8eSsaN4J49lLkncshnp3Quue0JpAnOSD4LXAJedAI/wGnVyS6FteAOKhZz6o5fk+GlwI3KgKB1aXf1kxRYApjB/Yhjrb4ONtCHHXtwAiizNQO3EGUie3AFKLM0T4gUoImamdNSAnMd2AdAug4xZb6nwg0igPNzwMzhYGpfYr3CgNjO3CuMHBmBw4UBvY0DwsZjOpxS+/ACoTXpuq1gpF/cevS9Al5kgTBrZtdBznEsxz/7r0bjyGeDYtuF94hnkwe3c+MkVf8sY5cN1ydig5OgnaZC8w1pCRLBFVsWhvYOKyAN2j4WqIPMMf+SnCKHSL03ZfoSv+G+ALX+5OvKqicuQAAAABJRU5ErkJggg==);
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.5em;
    height: 20px;
    width: 20px;
    margin: auto;
    text-align: right;
    pointer-events: none;
  }   
}

.validation {
  @extend %depth-2;
   color: white;
   background-color: $theme-primary-bright;
   position: absolute;
   top: 100%;
   right: 0;
   margin-top: 2px;
   z-index: 10000;
   border-radius: 4px;
   font-size: 13px;
   line-height: 16px;
   opacity: 0;
   pointer-events: none;
   transition: 0.2s opacity ease, 0.2s transform ease;
   transform: translateY(-5px);
   white-space: nowrap;
   max-width: 93vw;

   &::before {
     content: '';
     border: 6px solid transparent;
     border-bottom-color: $theme-primary-bright;
     z-index: 10001;
     position: absolute;
     top: 0;
     left: 4px;
     margin-top: -12px;
     pointer-events: none;
   }

   &.right::before {
      right: 10px;
   }
}

.validation--error {
  background-color: $theme-failure;
  
  &::before {
    border-bottom-color: $theme-failure;
  }
}

.validation--suggestion {
  padding-right: 0;

  button {
    color: white;
    font-weight: 500;
    cursor: pointer;
    pointer-events: auto;
    transition: none;
    transform-origin: center center;
    display: inline-block;
    padding: 4px 12px;
    border: 1px solid darken($theme-primary-bright, 7%);
    border-radius: 4px;
    vertical-align: top;
    width: 50px;
    background-color: rgba(white, 0.1) !important;
    margin: 2px;

    &:hover {
      background-color: rgba(white, 0.3) !important;
    }

    &:focus {
      outline: none;
    }

    &:active {
      background-color: rgba(black, 0.1) !important;
    }

    &:first-of-type {
      // border-top-right-radius: 0;
      // border-bottom-right-radius: 0;
    }

    + button {
      margin-left: 0;
      margin-right: 4px;
      // border-top-left-radius: 0;
      // border-bottom-left-radius: 0;
    }
  }

  // button:first-of-type {
  // }
}

.validation__message {
  padding: 8px 10px;
  display: inline-block;
}

.hasInteracted .formInput,
.formInput.hasInteracted {
  &.hasSuggestion,
  &.hasError {
    .validation__wrapper::after {
      display: block;
    }  
  }

  &.hasSuggestion {
    .validation__wrapper {
      margin-bottom: 30px;
    }

    .form-control,
    .radio-material span,
    .checkbox-material span {
      border-color: $theme-primary-bright !important;  
    }
  }

  &.hasError {
    .form-control,
    .radio-material span,
    .checkbox-material span {
      border-color: $theme-failure !important;  
    }


    .validation__wrapper::after {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAABvUlEQVR4AWIYUWC7jAw3oLmyBoolCMLwc3f3hyW4QwQZZJAnuHtekB4h5JDhmmIR7u7u7u42jbM12+tyVd/Zzt/9rTagR7FHhHTC2RXw/ZGeBONAjEGMnk7rCUXwBLbpQTAZhFhI0lruK4hw8FVLwUIegvlayZmBAE9MtRCsFyBYr7acK1UEx0UtuYeEcRGCo5BVQ9AbFcHxUlruCWGD0bTv6mH9lAH818NYC9knSgqGU45KFrI+j7I+TCm5lywjrRjJlFHWH0EtJQQToQGFRiTTypIxyC33Cbnwh5Ecdrd/klMwE2m0huR2kFy6XHJGUBADyZ5x8F8OwQoxgvBQ5iFYIVXOnucD+BEl+5hn1k7KSBvk2eQJJf+UZ3YAeokR9BQwwl5R8m8E5D2Eyj0mrAhoME0oZjArIL8EPYUI+kFQZXz5yj0nHGggCD2f8xFMEFH8G6XOdxF14rnk3ovc+6fIXSyUd5hgqg4EU9jkftMCGggCv2iCpToSLGUWs5R4B76kCL6UWNPi7kjrklishmBgUCOxZse1oDufgEa4gWCHjgXbQHBFx4JLWs1dQfMZJAMJ8zoSA5dAcNP96xwLDPHqXbwIWQAAAABJRU5ErkJggg==);
    }
  }

  &.showErrors .validation,
  &.hasSuggestion .validation {
    opacity: 1;
    transform: translateY(0);
  }
}

.formQuestionRadio .validation__wrapper {
  display: inline-block;
  vertical-align: middle;
  
  &::after {
    right: -0.75em;
    top: -0.45em;
  }
}