@import "includes";

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  position: relative;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 0;
  cursor: pointer;
  text-decoration: none;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
      
  &:hover,
  &.hover {
    color: $link-color;
  }

  &:focus,
  &.focus {
    background-color: rgba(#999, 0.3);
    outline: none;
  }

  &.active {
    color: $link-color !important;
    background-color: rgba(#999, 0.4) !important;
    outline: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: rgba(black, .26);
      cursor: default;
      pointer-events: none;
    }
  }
}

.btn-text {
  background: transparent !important;
  border: none;
  padding: 0;
  margin: 0;
  color: $theme-accent;
  text-transform: none;
  font-weight: $font-weight-normal;
  letter-spacing: 0;
}
