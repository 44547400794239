$theme-light-text: rgba(black, 0.87);
$theme-light-secondary: rgba(black, 0.54);
$theme-light-hint: rgba(black, 0.26);
$theme-light-divider: rgba(black, 0.12);
$theme-light-highlight: rgba(black, 0.04);

$theme-dark-text: rgba(white, 1);
$theme-dark-secondary: rgba(white, 0.7);
$theme-dark-hint: rgba(white, 0.3);
$theme-dark-divider: rgba(white, 0.12);

// $theme-primary-light: #bbdefb; // 100
// $theme-primary: #2196f3;       // 500
// $theme-primary-dark: #1976d2;  // 700


// // Indigo
// $theme-primary-light: #c5cae9; // 100
// $theme-primary: #3f51b5;       // 500
// $theme-primary-dark: #303f9f;  // 700


// // Blue Gray
// $theme-primary-light: #cfd8dc; // 100
// $theme-primary: #607d8b;       // 500
// $theme-primary-dark: #455a64;  // 700


// Dark Gray
$theme-primary-light: #586475; // 100
//$theme-primary: #000321;       // 500
$theme-primary: #222225;
$theme-primary-accent: #586475;
$theme-primary-dark: #282d35;  // 700
$theme-primary-bright: #1976D2;

$theme-accent: #ff3958;
$theme-success: #4caf50;
$theme-failure: #b71c1c;
$theme-warning: #FBC02D;
$theme-template: #9619d2;
$theme-column: #4caf50;
// $theme-accent: #00C853;

$body-bg:               #fff;
$text-color:            $theme-light-text;
$link-color:            $theme-accent;
$link-hover-color:      darken($link-color, 15%);

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-button: $font-weight-medium;

$font-family-sans-serif:  "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-caption:       12px;
$font-size-body:          13px;
$font-size-button:        14px;
$font-size-subhead:       14px;
$font-size-input:         16px;
$font-size-title:         20px;
$font-size-headline:      24px;
$font-size-display:       34px;
$font-size-base:          $font-size-body;

$line-height-caption:     20px;
$line-height-body:        20px;
$line-height-button:      20px;
$line-height-subhead:     24px;
$line-height-title:       28px;
$line-height-headline:    32px;
$line-height-display:     40px;

$line-height-base:        $line-height-body;
$line-height-computed:    $line-height-body;

$border-radius-base: 3px;

$element-top-margin: 8px;
$element-bottom-margin: 16px;

$card-padding: 20px;
$card-border-radius: $border-radius-base;




$btn-color: $theme-primary;
$btn-raised-bg: $theme-primary;
$btn-floating-bg: $theme-accent;


$screen-xx:                  280px;
$screen-xx-min:              $screen-xx;

$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

$screen-md:                  992px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

$screen-xx-max:              ($screen-xs-min - 1);
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);


$grid-columns:              12;
$grid-gutter-width:         30px;
$grid-float-breakpoint:     $screen-sm-min;
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);


$container-xs:                 (672px + $grid-gutter-width);
$container-tablet:             (720px + $grid-gutter-width);
$container-sm:                 $container-tablet;
$container-desktop:            (940px + $grid-gutter-width);
$container-md:                 $container-desktop;
$container-large-desktop:      (1140px + $grid-gutter-width);
$container-lg:                 $container-large-desktop;



$z-indexes: (
  date-range-picker-date-background: -1,
  input-font-family-dummy: -1,
  builder-condition-group-operator: 1,
  builder-editor-card-alert: 1,
  builder-editor-card-form: 1,
  builder-editor-card: 1,
  builder-page-overlay: 1,
  builder-page-preview-delete: 1,
  builder-page-preview-name: 1,
  builder-page-preview-toolbar: 1, 
  builder-page-preview-video-icon: 1,
  builder-page: 1,
  builder-tasks-dragging: 1,
  builder-tasks-menu: 1,
  date-range-picker-ranges: 1,
  interface-mode-indicator: 1,
  reporting-chart-tooltip: 1,
  reporting-funnel-caption: 1,
  reporting-funnel-gridlines: 1,
  reporting-funnel: 1,
  block-form-button: 2,
  block-form-question: 2,
  builder-column: 2,
  builder-page-current: 2,
  builder-tasks-label: 2,
  interface-mode-button: 2,
  reporting-funnel-bar: 2,
  header: 10,
  input-color-popover-item-over: 100,
  input-color-popover: 100,
  input-font-family-dropdown: 100,
  redactor-dropdown-color: 100,
  dropdown: 300,
  date-range-picker: 310,
  builder-page-preview: 400,
  builder-column-drag-handle: 998,
  builder-element-active: 999,
  builder-insertion-point: 1000,
  builder-insertion-control: 1001,
  builder-tasks: 1002,
  builder-tasks-active: 1003,
  builder-element-over: 1004,
  previewer-device-toolbar: 1004,
  network: 1004,
  builder-toolbar: 1005,
  builder-insertion-point-hover: 1007,
  builder-variable-warning: 1010,
  btn-action: 1100,
  tooltip: 1101,
  menu: 1102,
  snackbar: 1103,
  dialog: 1500,
  viz-node-info: 2000,
  date-range-picker-dropdown: 3000,
  modal-overlay: 4000,
  modal: 4001,
  image-upload-dropdown-background: 100000,
  image-upload-dropzone: 100001,
  builder-resize-overlay: 300000,
  browser-warning: 2147483647
);


// If you change any of these transition times, be sure to change
// transitionEnterTimeout/transitionLeaveTimeout on every component using
// CSSTransitionGroup!
$builder-transition: 0.2s ease;
$builder-page-transition: 0.3s ease;

$builder-page-previews-height: 203px;
