@import "includes";
@import "presenter/reboot";
@import "presenter/buttons";
@import "presenter/forms";
@import "presenter/grid";
@import "app/components/loading";
@import "app/views/common";

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.isEmbedded .page {
  min-height: 0;
}

.regionMain {
  flex-grow: 1;
  width: 100%;
}

.regionMain,
.regionHeader,
.regionFooter {
  flex-shrink: 0;
}


@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .page.isFixed .container-fluid {
    max-width: $container-desktop;
  }
}

@media (min-width: $screen-lg-min) {
  .page.isFixed .container-fluid {
    max-width: $container-large-desktop;
  }
}

.embedWarning {
  font-size: 16px;
  text-align: center;
  border: 1px dashed #ccc;
  padding: 36px 24px;
  line-height: 1.5;
}
