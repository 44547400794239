@import "includes";

.form-control {
  display: block;
  width: 100%;
  margin: 0;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  box-shadow: none;
  outline: none;
  transition: 0.3s;

  &::-moz-placeholder {
    color: $theme-light-hint;
    opacity: 1;
  }

  &:-ms-input-placeholder {
  color: $theme-light-hint;
}

  &::-webkit-input-placeholder  {
    color: $theme-light-hint;
  }
  
  &:-webkit-autofill {
    background-color: rgba(250, 255, 189, 0.5);
  }

  &[disabled],
  &[readonly] {
    cursor: not-allowed;
    background-color: $theme-light-divider;
    opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
  }
}

.help-block {
  display: block;
  margin: 0;
  font-size: $font-size-caption;
  color: $theme-light-secondary;
  text-align: left;
  line-height: 1.5;
}

.help-block a {
  border-bottom: 1px dashed currentColor;
}


span.required {
  color: $theme-accent;
  margin-left: 3px;
}

.form-label {
  display: block;
}


.input-numeric {
  white-space: nowrap;
  
  > input {
    display: inline-block;
    width: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    background-color: transparent;
    background-image: none;
    border: none;
    transition: none;
    vertical-align: top;
    text-align: inherit;
    box-shadow: none;
    outline: none;
  }
}

.input-numeric[data-symbol]::before {
  content: attr(data-symbol);
  height: 100%;
  vertical-align: text-bottom;
  text-align: left;
  pointer-events: none;
  user-select: none;
  opacity: 0.75;
  margin-right: 2px;
}

.input-numeric input[disabled] {
  cursor: not-allowed;
  opacity: 1;
}








.radio-material,
.checkbox-material {
  user-select: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  color: $text-color;
  margin: -1px 8px 0 0;
  position: relative;
  
  span {
    position: relative;
    border: 1px solid $theme-light-hint;
    background-color: white;
    display: block;
    transition: 0.14s;
    
    i {
      position: absolute;
    }
  }  
  
  input:focus ~ span {
    box-shadow: 0 0 0px 2px white;
  }

  input:checked ~ span {
    border-color: $theme-accent;
    
    &:after {
      background-color: $theme-accent; // IE 8
      background-color: rgba($theme-accent, 0.12);   
    }
  }

  &[disabled],
  &.disabled,
  fieldset[disabled] & {
    cursor: default;
    
    span {
      border-color: rgba(black, 0.26);

      &:after {
        background-color: rgba(black, 0.12);
      }
    }
  }
  
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    left: 3px;
    top: 3px;
    z-index: -1;
    opacity: 0;
  }

  + span,
  + label {
    cursor: pointer;
  }
}

.checkbox-material {
  span {
    border-radius: $border-radius-base;
    width: 17px;
    height: 17px;
    
    i {
      transform: rotate(45deg);
      top: 0;
      left: 5px;
      width: 6px;
      height: 12px;
      border-style: solid;
      border-top: none;
      border-left: none;
      border-right-width: 2px;
      border-bottom-width: 2px;
      border-color: white;
      visibility: hidden;
    }
  }

  
  
  input:checked ~ span {
    background-color: $theme-accent;

    i {
      animation: checkmark-expand 0.14s ease-out forwards;
      visibility: visible;
    }
  }

  &[disabled],
  &.disabled,
  fieldset[disabled] & {
    span {
      background-color: rgba(black, 0.26) !important;
      border-color: transparent  !important;
    }
  }
}


.radio-material {
  span {
    border-radius: 50%;
    width: 19px;
    height: 19px;
    
    i {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 11px;
      height: 11px;
      background-color: $theme-accent;
      border-radius: 50%;
      transform: scale(0);
      transition: transform 0.3s ease;
    }
  }



  input:checked ~ span i {
    transform: scale(1);
  }

  &[disabled],
  &.disabled,
  fieldset[disabled] & {
    span {
      border-color: rgba(black, 0.26) !important;

      i {
        background-color: rgba(black, 0.26);
      }
    }
  }
}



@keyframes checkmark-expand {
  0% {
    transform: rotate(45deg);
    top: 7px;
    left: 5px;
    width: 2px;
    height: 4px;
  }
  100% {
    transform: rotate(45deg);
    top: 0px;
    left: 5px;
    width: 6px;
    height: 12px;
    border-right-width: 2px;
    border-bottom-width: 2px;
  }
}