@import "includes";

.loading {
  display: inline-block;
  width: 15px;
  height: 20px;
  background-image: url('~images/loading.gif');
  background-repeat: no-repeat;
  background-size: 15px 20px;
  vertical-align: middle;
  opacity: 0;
  animation: 0.25s ease-in 0.5s loading both;
}

.loading.loading__immediate {
  animation: 0.25s ease-in loading both;
}

.loading__cascade {
  text-align: center;
  height: 200px;
  padding: 90px 0;
}

.loading__placeholder {
  display: block;
  margin: 20px auto;
}

.loading__message::after {
  content: 'Please wait, still loading…';
  width: 200px;
  display: block;
  margin-left: -90px;
  margin-top: 36px;
  opacity: 0;
  animation: 0.25s ease-in 3s loading both;
  color: $theme-light-secondary;
  font-size: 16px;
  line-height: 1.5;
}


.pageNotice .loading,
.pageLoader .loading {
  position: absolute;
  top: 30%;
  z-index: 1000000;
  width: auto;
  height: auto;
}

.pageLoader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000000;
  background-color: rgba(255, 255, 255, 0.75);
  opacity: 0;
  animation: 0.25s ease-in 0.75s loading both;
  text-align: center;
}

.pageLoader ~ .page {
  pointer-events: none;
}

.pageNotice .pageLoader__button {
  display: block;
  margin: 10px auto 0 auto;

  i {
    vertical-align: middle;
  }
}

@keyframes loading {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}